.map-navigator-box {
    height: calc(100vh - 160px);
}
.map-overlay-navigator {
    background-color: white;
    color: black;
    margin-bottom: -50px;
    z-index: 10000;
    position: absolute;
    left: 60px;
    top: 155px;
    width: 45px;
    height: 45px;
    padding: 10px;
    cursor: pointer;
}
.bolder {
    font-weight: bolder;
    font-size: 1.2em;
}
.with-pointer-events {
    pointer-events: all!important;
    cursor: pointer!important;
}
.box120 {
    max-height: 50px;
    overflow: auto;
    margin-top: 35px;
}

.box200 {
    max-height: 175px;
    overflow: auto;
    margin-top: 35px;
}
.top-slider-separator {
    margin-top: 85px;
}
.input-field {
    margin-top: 0px;
    margin-bottom: 0px;
}
.bottom-separator {
    padding-bottom: 20px;
}

#floor-potential-slider, #max-reserve-slider, #max-potential-m2-slider {
    height: 10px;
}

#floor-potential-slider .noUi-connect {
    background: #F44336;
}
#max-reserve-slider .noUi-connect {
    background: #F44336;
}
#max-potential-m2-slider .noUi-connect {
    background: #F44336;
}

#floor-potential-slider .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
    right: -9px; /* half the width */
    border-radius: 9px;
}
#max-reserve-slider  .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
    right: -9px; /* half the width */
    border-radius: 9px;
}
#max-potential-m2-slider .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
    right: -9px; /* half the width */
    border-radius: 9px;
}
.navigator-table-data-header {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    cursor:pointer;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 6px 16px;
    vertical-align: top;
}
.navigator-table-data {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 6px 16px;
    vertical-align: top;
}
.small-text {
    font-size: 0.8em;
}
.medium-text {
    font-size: 1.2em;
}
.left-separator {
    margin-left: 5px;
}
.hidden {
    display: none;
}
.no-border {
    border: 0px;
}
.no-padding {
    padding: 0px;
}
.highlighted-cell {
    font-weight: bold;
    background-color: RGBA(255, 192, 0,0.4);
    text-align: center;
}
.highlighted-column {
    font-weight: bolder;
    text-align: center;
}
.map-table {
    width: 300px;
    height: 300px;
}
[role=tooltip].popup-content {
    width: 312px!important;
}
.table-navigator {
    table-layout: fixed;
    word-break: break-word;
}