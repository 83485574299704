.report-table-data {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 6px 16px;
    vertical-align: top;
}
.no-border {
    border: 0px;
}
.no-padding {
    padding: 0px;
}
.highlighted-cell {
    font-weight: bold;
    background-color: RGBA(255, 192, 0,0.4);
    text-align: center;
}
.highlighted-column {
    font-weight: bolder;
    text-align: center;
}
.map-report-box {
    height: calc(50vh - 110px);
}
.small-graph-box {
    height: calc((50vh - 110px) / 4);
}
.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #f8f8f8ad;
}
.fp-container .fp-loader {
    top: 30%;
    left: 48%;
    z-index: 1000;
    position: absolute;
}
.bold {
    font-weight: bolder;
}
.custom-popup .leaflet-popup-content-wrapper {
    background:#2c3e50;
    color:#fff;
    font-size:12px;
    line-height:16px;
    opacity: 0.8;
    z-index: 20000;
    position: relative;
    right: 0;
    top: 0;
}
.custom-popup .leaflet-popup-content-wrapper a {
    color:rgba(255,255,255,0.5);
}
.custom-popup .leaflet-popup-tip-container {
    width:30px;
    height:15px;
}
.custom-popup .leaflet-popup-tip {
    border-left:15px solid transparent;
    border-right:15px solid transparent;
    border-top:15px solid #2c3e50;
}
.custom-popup .leaflet-popup-tip-container {
    display: none;
}

#map-buildings-info {
    background-color: black;
    color: white;
    opacity: 0.6;
    z-index: 100000;
    position: relative;
    margin-bottom: -43px;
    left: 100px;
    top: 25px;
    width: fit-content;
    padding: 10px;
}
#map-buildings-info-void {
    background-color: transparent;
    color: transparent;
    opacity: 0.6;
    z-index: 100000;
    position: relative;
    margin-bottom: -30px;
    left: 50px;
    top: 25px;
    width: fit-content;
    padding: 10px;
}

#map-zones-info {
    background-color: black;
    color: white;
    opacity: 0.6;
    z-index: 100000;
    position: relative;
    margin-bottom: -43px;
    left: 100px;
    top: 25px;
    width: fit-content;
    padding: 10px;
}
#map-zones-info-void {
    background-color: transparent;
    color: transparent;
    opacity: 0.6;
    z-index: 100000;
    position: relative;
    margin-bottom: -30px;
    left: 50px;
    top: 25px;
    width: fit-content;
    padding: 10px;
}

.map-overlay {
    background-color: white;
    color: black;
    margin-bottom: -50px;
    z-index: 10000;
    position: relative;
    left: 50px;
    top: 15px;
    width: 45px;
    height: 45px;
    padding: 10px;
    cursor: pointer;
}

#report-body table {
    /*table-layout: fixed;*/
    width: 100%;
    table-layout: auto !important;
}

#report-body  td {
    width: auto !important;
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
}